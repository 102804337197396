footer {
    position: relative;
    z-index: 0;
    font-size: 1rem;
    font-weight: 400;
    border-top: 1px solid #e5e5e5;
}

#footer-links {
    background: #FFF;
    color: #015976;
    position: relative;
    padding: 1rem 0;
}

#footer-links .links-group__name {
    font-size: 1rem;
    font-weight: 500;
    margin: 1em 0 .5em;
    padding-bottom: .1em;
}

#footer-links .links-group:not(.icons-group) .links-group__list {
    padding: 0;
    margin-left: -.5em;
}

#footer-links .links-group:not(.icons-group) .links-group__list li {
    position:relative;
    margin: .5rem 0;
}

#footer-links .links-group:not(.icons-group) .links-group__list li a {
    color: #007FA5;
    font-size: 1rem;
    position:relative;
    padding:.1em .5em;
}

#footer-links .links-group:not(.icons-group) .links-group__list li a:hover {
    color: rgb(0, 127, 165);
}

#footer-links .links-group:not(.icons-group) .links-group__list li a::before {
    content: '';
    display: block;
    position: absolute;
    height: 8px;
    width: 8px;
    border-left:1px solid rgba(0,127,165,1);
    border-top:1px solid rgba(0,127,165,1);
    top: 0px;
    left:0px;
    -webkit-transform: translate(-5px, -2px);
    -moz-transform: translate(-5px, -2px);
    -ms-transform: translate(-5px, -2px);
    -o-transform: translate(-5px, -2px);
    transform: translate(-5px, -2px);
    -webkit-transition: opacity .3s ease, -webkit-transform .3s ease;
    -moz-transition: opacity .3s ease, -moz-transform .3s ease;
    -ms-transition: opacity .3s ease, -ms-transform .3s ease;
    -o-transition: opacity .3s ease, -o-transform .3s ease;
    transition: opacity .3s ease, transform .3s ease;
    opacity:0;
}

#footer-links .links-group:not(.icons-group) .links-group__list li a::after {
    content: '';
    display: block;
    position: absolute;
    height: 8px;
    width: 8px;
    border-right:1px solid rgba(0,127,165,1);
    border-bottom:1px solid rgba(0,127,165,1);
    /*background-color: rgba(0,127,165,1);*/
    bottom: 0px;
    right:0px;
    -webkit-transform: translate(5px, 2px);
    -moz-transform: translate(5px, 2px);
    -ms-transform: translate(5px, 2px);
    -o-transform: translate(5px, 2px);
    transform: translate(5px, 2px);
    -webkit-transition: opacity .3s ease, -webkit-transform .3s ease;
    -moz-transition: opacity .3s ease, -moz-transform .3s ease;
    -ms-transition: opacity .3s ease, -ms-transform .3s ease;
    -o-transition: opacity .3s ease, -o-transform .3s ease;
    transition: opacity .3s ease, transform .3s ease;
    opacity:0;
}

#footer-links .links-group:not(.icons-group) .links-group__list li a:hover::before,
#footer-links .links-group:not(.icons-group) .links-group__list li a:hover::after {
    opacity:1;
}

#footer-links .links-group:not(.icons-group) .links-group__list li a:hover::before {
    -webkit-transform: translate(0px, 2px);
    -moz-transform: translate(0px, 2px);
    -ms-transform: translate(0px, 2px);
    -o-transform: translate(0px, 2px);
    transform: translate(0px, 2px);
}

#footer-links .links-group:not(.icons-group) .links-group__list li a:hover::after {
    -webkit-transform: translate(0px, -1px);
    -moz-transform: translate(0px, -1px);
    -ms-transform: translate(0px, -1px);
    -o-transform: translate(0px, -1px);
    transform: translate(0px, -1px);
}

#disclaimer {
    background: #014358;
    color: #FFF;
    position: relative;
}

footer .logo-container,
footer .text-container {
    height : 8rem;
}

footer .logo-container {
    max-width: 300px;
    margin: 0 auto;
}

footer .right {
    clear: both;
    float: none !important;
}

footer .text-container {
    height : 4rem;
    text-align: center;
    font-size: .8rem;
    padding: 0 0.9375rem;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 3;
    }

    footer .right {
        clear: none;
        float: right !important;
    }

    footer .text-container {
        height : 8rem;
    }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

