main.page-forgot .success.scalableField {
	margin: -4.5em ;
}

main.page-forgot [class*="Moniker"]+span,
main.page-forgot h2[class*="Moniker"] {
	text-align: center;
}

main.page-forgot .success {
	display: none;
}

main.page-forgot .prompt p {
	font-size: 1.3em;
	text-align: justify;
}

main.page-forgot .prompt input {
	margin-bottom: 2em;
}

main.page-forgot .prompt button {
	width: auto;
}
