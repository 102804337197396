.alert-box {
    background-color: #fff;
    color:#414141;
    position: absolute;
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    overflow: hidden;
}

.alert-box.transparent {
    -webkit-transform: scale3d(.7, .7, 1) translate3d(0, -50%, 0);
    -moz-transform: scale3d(.7, .7, 1) translate3d(0, -50%, 0);
    -ms-transform: scale3d(.7, .7, 1) translate3d(0, -50%, 0);
    transform: scale3d(.7, .7, 1) translate3d(0, -50%, 0);
}

.alert-box.transparent.closing {
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 50%, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 50%, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 50%, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 50%, 0);
}

.alert-box-title {
    text-align: center;
    font-size: 2em;
    line-height: 70px;
    font-weight: 300;
    color: rgb(97, 97, 97);
    color: #545454;
    border-bottom: 3px solid #545454;
    background-color: #e5e5e5;
}

.no-title .alert-box-title {
    display: none;
}

[class*='severity--'] .alert-box-title {
    border-bottom: none;
}

.severity--info .alert-box-title {
    color: #0277bd;
    background-color: #e1f5fe;
}

.severity--warning .alert-box-title {
    color: #f57c00;
    background-color: #fff3e0;
}

.severity--danger .alert-box-title {
    color: #d32f2f;
    background-color: #ffebee;
}

.severity--success .alert-box-title {
    color: #00c853;
    background-color: #e0f2f1;
}

.alert-box-contents {
    text-align: center;
    width: 100%;
    position: relative;
    font-size: 1.5em;
    line-height: 1.3em;
    font-weight: 300;
    overflow: auto;
}

.severity--info .alert-box-contents {
    border-top:3px solid #0277bd;
}

.severity--warning .alert-box-contents {
    border-top:3px solid #f57c00;
}

.severity--danger .alert-box-contents {
    border-top:3px solid #d32f2f;
}

.severity--success .alert-box-contents {
    border-top:3px solid #00c853;
}

.alert-box-contents .container {
    padding: 1em;
    width: 100%;
}

.alert-box-buttons {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}

.alert-box-buttons button a {
    color: whiteSmoke !important;
    /* hack to make <a> occupy all the button space, so clicking anywhere on the button
       triggers the <a>*/
    display: block;
    margin: -.5em -1em;
    padding: .5em 1em;
}

.alert-box-buttons > * {
    margin-left: 1.5em !important;
    margin-bottom: 0 !important;
}

.alert-box-buttons > *:first-child {
    margin-left: 0 !important;
}

.fallback {
    position: fixed;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    background: rgba(0,0,0,.5);
    z-index:10000;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
}
