.lead {
    font-weight: 400;
    font-size: 20px;
}

h1 {
    margin: .67em 0;
    margin-top: 0 !important;
    color: rgb(90,90,90);
    position: relative;
    font-size: 2.125em;
    text-align: left;
    font-weight: 400;
}

#features-container h1 {
    font-size: 3.6em;
    text-align: center;
    font-weight: 300;
}

h2 {
    font-size: 1.5em;
    margin: 0.83em 0;
    font-weight: 400;
    line-height: inherit;
}

h3 {
    font-size: 1.17em;
    margin: 1em 0;
}

h4 {
    font-size: 1em;
    margin: 1.33em 0;
}

pre {
    padding: 1.25rem;
    border: none;
}

code,
pre code {
    font-family: 'Source Code Pro', monospace;
    font-size: 1rem;
    color: #555555;
    background-color: #f5f5f5;
    border-radius: 2px;
    padding: 2px 4px;
}

pre code {
    padding: 0;
    white-space: pre;
}

kbd {
    color: #fff;
    font-family: 'Source Sans Pro', monospace;
    background-color: #757575;
    font-size: 1rem;
    border-radius: 2px;
    padding: 2px 4px;
}

blockquote {
    color: #555555;
    font-size: 1.125rem;
    border-color: #0277bd;
}

form {
    margin: 0 0 1em;
}

label {
    font-size: 0.875em;
    color: #4d4d4d;
    cursor: pointer;
    display: block;
    font-weight: 500;
    margin-bottom: 0.1875em;
}

input[type="file"],
input[type="checkbox"],
input[type="radio"],
select {
    margin: 0 0 1em 0;
}

table {
    background: white;
    margin-bottom: 1.25em;
    border: solid 1px #dddddd;
}

table thead, table tfoot {
    background: whitesmoke;
    font-weight: bold;
}

table thead tr th, table tfoot tr th, table tbody tr td, table tr td, table tfoot tr td {
    display: table-cell;
    line-height: 1.125em;
    vertical-align: middle;
}

table tr th, table tr td {
    padding: 0.5625em 0.625em;
    font-size: 0.875em;
    color: #222222;
    vertical-align: middle !important;
}

form .row .row {
    margin: 0 -0.5em;
}

form .row .row .column, form .row .row .columns {
    padding: 0 0.5em;
}

.signup-form label {
    font-size: 1.2em;
    font-weight: 300;
}

.signup-form .buttons-div {
    margin-top: 32px;
    margin-bottom: 0;
}

/* TOS and privacy pages */
.hide-for-small, .hide-for-medium-down, .hide-for-large-down {
    display: none !important;
}

@media only screen and (min-width: 768px) {
    .hide-for-small {
        display: inherit !important;
    }
}

/** Some utility classes borrowed from Bootstrap 4 **/
/** https://github.com/twbs/bootstrap/blob/v4-dev/dist/css/bootstrap.css#L6016 **/
.m-x-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.m-a-0 {
    margin: 0 0 !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-r-0 {
    margin-right: 0 !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-l-0 {
    margin-left: 0 !important;
}

.m-x-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.m-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.m-a-1 {
    margin: 1rem 1rem !important;
}

.m-t-1 {
    margin-top: 1rem !important;
}

.m-r-1 {
    margin-right: 1rem !important;
}

.m-b-1 {
    margin-bottom: 1rem !important;
}

.m-l-1 {
    margin-left: 1rem !important;
}

.m-x-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.m-y-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.m-a-2 {
    margin: 1.5rem 1.5rem !important;
}

.m-t-2 {
    margin-top: 1.5rem !important;
}

.m-r-2 {
    margin-right: 1.5rem !important;
}

.m-b-2 {
    margin-bottom: 1.5rem !important;
}

.m-l-2 {
    margin-left: 1.5rem !important;
}

.m-x-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.m-y-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.m-a-3 {
    margin: 3rem 3rem !important;
}

.m-t-3 {
    margin-top: 3rem !important;
}

.m-r-3 {
    margin-right: 3rem !important;
}

.m-b-3 {
    margin-bottom: 3rem !important;
}

.m-l-3 {
    margin-left: 3rem !important;
}

.m-x-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.m-y-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.p-a-0 {
    padding: 0 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.p-l-0 {
    padding-left: 0 !important;
}

.p-x-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.p-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.p-a-1 {
    padding: 1rem 1rem !important;
}

.p-t-1 {
    padding-top: 1rem !important;
}

.p-r-1 {
    padding-right: 1rem !important;
}

.p-b-1 {
    padding-bottom: 1rem !important;
}

.p-l-1 {
    padding-left: 1rem !important;
}

.p-x-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.p-y-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.p-a-2 {
    padding: 1.5rem 1.5rem !important;
}

.p-t-2 {
    padding-top: 1.5rem !important;
}

.p-r-2 {
    padding-right: 1.5rem !important;
}

.p-b-2 {
    padding-bottom: 1.5rem !important;
}

.p-l-2 {
    padding-left: 1.5rem !important;
}

.p-x-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.p-y-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.p-a-3 {
    padding: 3rem 3rem !important;
}

.p-t-3 {
    padding-top: 3rem !important;
}

.p-r-3 {
    padding-right: 3rem !important;
}

.p-b-3 {
    padding-bottom: 3rem !important;
}

.p-l-3 {
    padding-left: 3rem !important;
}

.p-x-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.p-y-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.pos-f-t {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
