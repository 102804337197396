/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

#content > #banner {
    background-color: #014358;
    color: #fafafa;
    text-align: center;
}

#banner h1 {
    font-size: 2.5em;
    font-weight: 400;
    color: white;
    margin: auto;
    text-align:center;
}

#key-points,
#support {
    background-color: #fff;
}

#features,
#start {
    background-color: #f5f5f5;
}

#key-points img {
    margin-top: 3rem;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    #content > #banner {
        height: 60vh;
    }

    #banner > .container {
        position: relative;
        display: block;
        top: 50%;
        transform: translateY(-50%);
    }

    #features h2 {
        /** Hack to fix the issue with the text wrap pushing thi images down **/
        height: 1.5em;
    }

    #key-points img {
        margin-top: 2rem;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    #key-points img {
        margin-top: 1rem;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    #features h2 {
        height: auto;
    }
}
