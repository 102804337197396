@media only screen and (max-width: 769px) {

    main.page-landing #content > section:last-child::after,
    #content > section:only-child::after {
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, .1);
    }

    footer.page-landing {
        box-shadow:none !important;
    }

    #web-development .extend-8 .wd-img {
        position: relative;
        display: none;
        margin: 0 auto;
    }

    #web-development .extend-8 .wd-img.visible {
        display: block;
    }

    #web-development .extend-4 {
        border-left: none !important;
    }
}

@media only screen and (min-width: 769px) {

    main.page-landing .featureTitle {
        margin-top: 0;
    }

    main.page-landing .featureDesc {
        padding-top: 1em;
    }

    #secondFeature img {
        margin-top: -3em;
    }
}

.includesFootnote {
    border-bottom: 1px dotted rgb(90, 90, 90);
    cursor: pointer;
}

.includesFootnote sup {
    display:inline-block;
    top: -.8em;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .3s ease;
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -o-transform: scale(1,1);
    transform: scale(1,1);
}

.includesFootnote:hover sup {
    -webkit-transform: scale(1.5,1.5);
    -moz-transform: scale(1.5,1.5);
    -ms-transform: scale(1.5,1.5);
    -o-transform: scale(1.5,1.5);
    transform: scale(1.5,1.5);
}

.footnotes.scalableField{
    margin: -3em auto;
    font-size: .7em;
    padding: 1em;
    display: block;
    text-align: justify;
    border: 1px dotted rgba(90, 90, 90, 1);
}

.footnotes.scalableField.active {
    margin: 0 0 1em 0  !important;
}

.orPurchase {
    position: relative;
    text-align: left;
    color: rgb(90, 90, 90) ;
    margin-top: 0.5em !important;
    font-size: 1.3em;
}

.purchaseMoniker {
    text-align:center;
    font-weight: 300;
}

.purchaseMoniker .button {
    margin: 0;
}

main.page-landing #features {
    background-color: transparent !important;
    border-bottom: none;
}

main.page-landing .sectionTitle {
    text-align: center !important;
    font-size: 3.5em !important;
    font-weight: 300 !important;
    color: rgb(85, 85, 85) !important;
    margin:0 !important;
}

[id$="Feature"] {
    padding: 3em 0 !important;
    position: relative;
    font-weight: 300;
    text-align:justify;
}

[id$="Feature"]::after {
    position:relative;
    content:"";
    height: 1px;
    width:100%;
    background: rgba(0,0,0,.1);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 60%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(0,0,0,0.2)), color-stop(60%,rgba(0,0,0,0)));
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 60%);
    background: -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 60%);
    background: -ms-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 60%);
    background: radial-gradient(ellipse at center,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 60%);
    bottom: -3em;
}

[id$="Feature"]:last-of-type::after {
    visibility:hidden;
}

main.page-landing .featureTitle {
    color: rgb(85, 85, 85);
    font-size: 1.6em;
    margin: 0.83em 0;
    font-weight: 400;
}

.featureDesc {
    color: #222222;
}

.featureImg a {
    margin: 1em 0;
    display: block;
}

#altForm {
    z-index: 2;
}

.featureParagraph {
    font-size: 1.3em;
}

footer.page-landing {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .32), 0 1px 0px 0px rgba(255, 255, 255, 1) inset;
}

/*
 * Screenshot styling
 */
.intro-screenshot {
    max-width: 800px;
    max-height: 500px;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
    display: block;
}

.main-signup-form .buttons-div {
    margin-top: 3.5em;
}

main.page-landing {
    background-color: white;
}

#web-development::after {
    display: none !important;
}

#web-development {
    border-bottom: none !important;
    background-color: whitesmoke !important;
}

#web-development .why.standard-features {
    margin-bottom: 32px;
}

#web-development .extend-4 {
    border-left: 1px solid rgb(179, 179, 179);
}

#web-development .wrapper {
    padding-bottom: 8em;
}

.wd-img {
    position: absolute;
    width: calc(100% - (2 * 0.9375em));
    opacity: 0;
    transition: all .3s ease;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,.5);
    z-index: 0;
    transform: translate3d(0, -10%, 0);
}

.wd-img.visible {
    opacity: 1;
    z-index: 1;
    transform: translate3d(0, 0, 0);
}

#more-features {
    padding: 3em 0;
}

.github-related {
    text-align: center !important;
}

.github-related .octocat {
    opacity: .7;
}

#github-signup-link:hover .octocat {
    opacity: 1;
}

#intro .tryOutMoniker {
    margin-bottom: 2em;
}
