.scalableField {
    box-sizing:border-box;
    min-height: 0;
    -webkit-transform: scale(.5,.5);
    -moz-transform: scale(.5,.5);
    -ms-transform: scale(.5,.5);
    -o-transform: scale(.5,.5);
    transform: scale(.5,.5);
    -webkit-transition: -webkit-transform .3s ease, visibility .3s ease, opacity .3s ease, margin .3s ease .3s;
    -moz-transition: -moz-transform .3s ease, visibility .3s ease, opacity .3s ease, margin .3s ease .3s;
    -ms-transition: -ms-transform .3s ease, visibility .3s ease, opacity .3s ease, margin .3s ease .3s;
    -o-transition: -o-transform .3s ease, visibility .3s ease, opacity .3s ease, margin .3s ease .3s;
    transition: transform .3s ease, visibility .3s ease, opacity .3s ease, margin .3s ease .3s;
    visibility: hidden;
    opacity:0;
    z-index: 4;
    position:relative;
    clear:both;
}

.scalableField.active {
    -webkit-transition: -webkit-transform .3s ease, visibility .3s ease, height .3s ease, opacity .3s ease, margin .3s ease;
    -moz-transition: -moz-transform .3s ease, visibility .3s ease, height .3s ease, opacity .3s ease, margin .3s ease;
    -ms-transition: -ms-transform .3s ease, visibility .3s ease, height .3s ease, opacity .3s ease, margin .3s ease;
    -o-transition: -o-transform .3s ease, visibility .3s ease, height .3s ease, opacity .3s ease, margin .3s ease;
    transition: transform .3s ease, visibility .3s ease, height .3s ease, opacity .3s ease, margin .3s ease;
    /*height: auto;*/
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -o-transform: scale(1,1);
    transform: scale(1,1);
    visibility: visible;
    opacity:1;
}

.signupForm .scalableField, .loginForm .scalableField {
    margin: -1em auto !important;
}

.scalableField.error {
    padding: .3em;
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid #900101;
    width: 95%; /* fixes weird right border clipping issue*/
    color: black;
}

.scalableField.active.error {
    margin: .5em auto !important;
}

.scalableField.info {
    padding: .3em;
    border: 1px solid rgba(144, 130, 1, 1);
    background: rgba(144, 130, 1, 0.1);
    width: 95%; /* fixes weird right border clipping issue*/
    color: black;
}

.scalableField.active.info {
    margin: .5em auto !important;
}
