/** Import site wide CSS **/
@import url('../js/vendor/bootstrap/dist/css/bootstrap.css');

/** Import everything else after bootstrap to build/overwrite on it **/
@import url('bootstrap-overrides.css');
@import url('fonts.css');
@import url('grid.css');
@import url('general.css');
@import url('../../../../../app/static/app/css/core/helpers.css');
@import url('hoverbubbles.css');
@import url('../../../../../app/static/app/css/core/severity.css');

/** Import components **/
@import url('components/header.css');
@import url('components/footer.css');
@import url('components/buttons.css');
@import url('components/forms.css');
@import url('components/scalable-notification.css');

/** Import all pages' CSS **/
@import url('pages/company.css');
@import url('pages/contact.css');
@import url('pages/features.css');
@import url('pages/forgot.css');
@import url('pages/home.css');
@import url('pages/landing.css');
@import url('pages/login.css');
@import url('pages/pricing.css');
@import url('pages/privacy.css');
@import url('pages/resetpassword.css');
@import url('pages/tos.css');
@import url('pages/verify_email.css');
@import url('pages/join.css');
@import url('pages/join/base.css');
@import url('pages/join/welcome.css');
@import url('pages/aftertrial.css');
@import url('pages/students.css');
