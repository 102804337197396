main.page-privacy h1::after {
	content:"";
	position:absolute;
	height:1px;
	width:100%;
	bottom:0;
    left: 0;
	background: -moz-linear-gradient(left,  rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.35) 50%, rgba(0,0,0,0) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0.35)), color-stop(50%,rgba(0,0,0,0.35)), color-stop(100%,rgba(0,0,0,0)));
	background: -webkit-linear-gradient(left,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0) 100%);
	background: -o-linear-gradient(left,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0) 100%);
	background: -ms-linear-gradient(left,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0) 100%);
	background: linear-gradient(to right,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#59000000', endColorstr='#00000000',GradientType=1 );
}

#pageNavigation {
	height:100%;
	width:100%;
}

#pageNavigation {
	margin-top: 1em;
}

#pageNavigation .row {
	padding: 5px 10px;
	font-size: 1.2em;
	font-weight: 300;
}

#pageNavigation div {
	position:relative;
}

#pageNavigation div a:hover {
	color: rgb(0, 127, 165);
}

#pageNavigation div::before {
	content: '';
	display: block;
	position: absolute;
	height: 10px;
	width: 6px;
	border-left:1px solid rgba(0,127,165,1);
	border-top:1px solid rgba(0,127,165,1);
	top: 0px;
	left:5px;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: opacity .3s ease, -webkit-transform .3s ease;
	-moz-transition: opacity .3s ease, -moz-transform .3s eases;
	-ms-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease, transform .3s ease;
	opacity:0;
}

#pageNavigation div::after {
	content: '';
	display: block;
	position: absolute;
	height: 10px;
	width: 6px;
	border-left:1px solid rgba(0,127,165,1);
	border-bottom:1px solid rgba(0,127,165,1);
	/*background-color: rgba(0,127,165,1);*/
	bottom: 0px;
	left:5px;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: opacity .3s ease, -webkit-transform .3s ease;
	-moz-transition: opacity .3s ease, -moz-transform .3s eases;
	-ms-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease, transform .3s ease;
	opacity:0;
}

#pageNavigation div:hover::before,
#pageNavigation div:hover::after {
	opacity:1;
}

#pageNavigation div:hover::before {
	-webkit-transform: translateY(7px);
	-moz-transform: translateY(7px);
	-ms-transform: translateY(7px);
	-o-transform: translateY(7px);
	transform: translateY(7px);
}

#pageNavigation div:hover::after {
	-webkit-transform: translateY(-5px);
	-moz-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	-o-transform: translateY(-5px);
	transform: translateY(-5px);
}

main.page-privacy .sectionLetter {
	float: left;
}

main.page-privacy .sectionName {
	overflow: hidden;
	display: block;
	padding-left: 5px;
}

main.page-privacy #sectionsWrapper section {
	position: relative;
	/*padding: 0 1em;*/
	margin-bottom:2em;
	background-clip: padding-box;
	box-shadow: none;
	background-color: transparent;
	-webkit-transition: background-color .3s ease, box-shadow .3s ease;
	-moz-transition: background-color .3s ease, box-shadow .3s ease;
	-ms-transition: background-color .3s ease, box-shadow .3s ease;
	-o-transition: background-color .3s ease, box-shadow .3s ease;
	transition: background-color .3s ease, box-shadow .3s ease;
}

main.page-privacy #sectionsWrapper section:before, #sectionsWrapper section:before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	box-shadow: none;
	display: block;
	left: 0;
	z-index: -1;
	-webkit-transition: box-shadow .3s ease;
	-moz-transition: box-shadow .3s ease;
	-ms-transition: bbox-shadow .3s ease;
	-o-transition: box-shadow .3s ease;
	transition: box-shadow .3s ease;
}

main.page-privacy #sectionsWrapper section:target, #sectionsWrapper section.active {
	background-color: white;
	box-shadow: 0px 0px 0px 1px rgba(0,0,0,.35) inset;
	z-index: 5;
}

main.page-privacy #sectionsWrapper section:target:before,
main.page-privacy #sectionsWrapper section.active:before {
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
}

main.page-privacy #sectionsWrapper p,
main.page-privacy #sectionsWrapper li {
	text-align:justify;
	padding: 0 1em;
}

main.page-privacy #sectionsWrapper li {
	padding: 5px 0;
}

main.page-privacy section h2 {
	font-size: 1.5em;
	font-weight: 400;
	position: relative;
	padding: 1em 0 .5em 0;
	padding-right: 75px;
	margin: 0;
	border:1px solid transparent;
	-webkit-transition: padding .3s ease, border .3s ease;
	-moz-transition: padding .3s ease, border .3s ease;
	-ms-transition: padding .3s ease, border .3s ease;
	-o-transition: padding .3s ease, border .3s ease;
	transition: padding .3s ease, border .3s ease;
}

main.page-privacy section h2::after {
	content: "";
	position: absolute;
	height: 1px;
	width: 100%;
	bottom: 0;
	left: 0;
	background-color: rgba(0,0,0,0.35);
}

main.page-privacy section h2::before {
	display: block;
	content: "";
	position: absolute;
	width: 15px;
	height: 15px;
	border-right: 1px solid rgba(0, 0, 0, .35);
	border-bottom: 1px solid rgba(0, 0, 0, .35);
	background-color: whitesmoke;
	left: 25px;
	bottom: -7px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	z-index: 1;
	-webkit-transition: left .5s ease;
	-moz-transition: left .5s ease;
	-ms-transition: left .5s ease;
	-o-transition: left .5s ease;
	transition: left .5s ease;
}

main.page-privacy section:target h2,
main.page-privacy section.active h2 {
	padding-left: 1em;
	background-color: rgb(240, 240, 236);
	border-left: 1px solid rgba(0,0,0,.35);
	border-right: 1px solid rgba(0,0,0,.35);
	border-top: 1px solid rgba(0,0,0,.35);
	border-bottom: transparent;
	box-shadow: 0px 1px 0px 0px rgba(255,255,255,1) inset;
    margin: 0 !important;
}

main.page-privacy section:target h2::before,
main.page-privacy section.active h2::before {
	background-color: rgb(240, 240, 236);
	left: 50%;
	margin-left:-15px;
}

main.page-privacy section ol {
	padding: 1em 2em 1em 40px;
	margin:0;
}

main.page-privacy .permalinks {
	position: absolute;
	top: 35px;
	right: 18px;
	font-size: .8em;
}
