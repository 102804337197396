/*
* HTML5 Boilerplate
*
* What follows is the result of much research on cross-browser styling.
* Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
* Kroc Camen, and the H5BP dev community and team.
*/

/* ==========================================================================
Base styles: opinionated defaults
========================================================================== */

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

html,
button,
input,
select,
textarea {
    color: rgb(24, 24, 24);
}

html, body {
    font-size: 16px;
    line-height: 1.4;
}

/*
* Remove text-shadow in selection highlight: h5bp.com/i
* These selection rule sets have to be separate.
* Customize the background color to match your design.
*/

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
* A better looking default horizontal rule
*/

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

/*
* Remove the gap between images and the bottom of their containers: h5bp.com/i/440
*/

img {
    vertical-align: middle;
    width: 100%;
    display: inline-block;
}

/*
* Remove default fieldset styles.
*/

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
* Allow only vertical resizing of textareas.
*/

textarea {
    resize: vertical;
    height: auto;
}

img,
object,
embed {
    max-width: 100%;
    height: auto;
}

object,
embed {
    height: 100%;
}

/* ==========================================================================
Author's custom general rules
========================================================================== */

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

html, body {
    font-family: 'Source Sans Pro', Tahoma, sans-serif !important;
    background-color: #014358;
    color: rgb(85, 85, 85);
    height: 100%;
}

main {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #F5F5F5;
}

main > section {
    position: relative;
    padding: 2rem 0;
    border-bottom: 1px solid #e5e5e5;
    background-color: #F5F5F5;
}

main > section.ribbon-section {
    padding: 0;
    height: 3em;
    line-height: 3em;
    text-align: center;
    background-color: #FFFDC6;
}

main > section:nth-child(even):not(.ribbon-section) {
    background: #F5F5F5;
}

main > section:nth-child(odd):not(.ribbon-section) {
    background: #FFF;
}

main > section:only-child:not(.ribbon-section) {
    background: #F5F5F5;
}

main > section:last-child:not(.ribbon-section) {
    background: #F5F5F5;
    border-bottom: none;
}

main .wrapper {
    padding: 0 .5em;
}

a {
    color: #0277bd;
    cursor: pointer;
}

a:hover {
    color: #00AFE4;
}

a:link, a:hover, a:visited, a:active {
    text-decoration: none;
}

hr {
    width: 70%;
    margin:25px auto 0;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea {
    background-color: rgb(255, 255, 255);
    color:rgb(80, 80, 80);
    position: relative;
    border: 1px solid rgb(175, 175, 175);
    box-shadow: none;
    -webkit-transition: box-shadow 0.1s ease 0s, border 0.1s ease 0s;
    -moz-transition: box-shadow 0.1s ease 0s, border 0.1s ease 0s;
    -ms-transition: box-shadow 0.1s ease 0s, border 0.1s ease 0s;
    -o-transition: box-shadow 0.1s ease 0s, border 0.1s ease 0s;
    transition: box-shadow 0.1s ease 0s, border 0.1s ease 0s;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    width:100%;
    display: block;
    font-size: 1rem;
    margin: 0 0 .5rem 0;
    padding: .25rem .5rem;
    font-weight: 400;
}

input[type="text"]:hover,
input[type="password"]:hover,
input[type="date"]:hover,
input[type="datetime"]:hover,
input[type="datetime-local"]:hover,
input[type="month"]:hover,
input[type="week"]:hover,
input[type="email"]:hover,
input[type="number"]:hover,
input[type="search"]:hover,
input[type="tel"]:hover,
input[type="time"]:hover,
input[type="url"]:hover,
textarea:hover {
    border: 1px solid rgb(2,98,130);
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
textarea:focus {
    border: 1px solid rgb(32, 152, 192) !important;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.22) inset;
    background-color: rgb(250, 250, 250);
    outline:none;
    background-image: -moz-linear-gradient(top,  rgba(0,0,0,0.01) 0%, rgba(0,0,0,0) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.01)), color-stop(100%,rgba(0,0,0,0)));
    background-image: -webkit-linear-gradient(top,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0) 100%);
    background-image: -o-linear-gradient(top,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0) 100%);
    background-image: -ms-linear-gradient(top,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0) 100%);
    background-image: linear-gradient(to bottom,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0) 100%);
}

input[type="text"][disabled],
input[type="password"][disabled],
input[type="date"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="month"][disabled],
input[type="week"][disabled],
input[type="email"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="time"][disabled],
input[type="url"][disabled],
textarea[disabled] {
    background-color: #dddddd;
}


input[type=checkbox] {
    border: 1px solid rgb(175, 175, 175);
    background-color: rgb(255, 255, 255);
    position: relative;
    /*height: 15px;*/
    width: 13px;
    height: 13px;
    margin: 0;
    cursor: pointer;
    -webkit-appearance: none;
    /*-moz-appearance: none;*/
    appearance: none;

}

input[type=checkbox]:hover, input[type=checkbox]:focus {
    border: 1px solid rgb(2,98,130);
    outline: none !important;
}

input[type=checkbox]:checked {
    border: 1px solid rgb(32, 152, 192) !important;
    background-color: rgb(250, 250, 250) !important;
}

input[type=checkbox]:checked::after {
    opacity: 1;
    content: '';
    position: absolute;
    width: 9px;
    height: 6px;
    background: transparent;
    top: 2px;
    left: 1px;
    border: 3px solid rgb(80, 80, 80);
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

textarea:focus, pre:focus {
    outline:none;
}

.octocat,
.octocat.black {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-size: contain;
    margin: 0 .5em;
    position: relative;
    top: .2em;
}
.octocat {
    background-image: url(../img/GitHub-Mark-Light-32px.png);
}

.octocat.black {
    background-image: url(../img/GitHub-Mark-32px.png);
}

/** Part of footer hack **/
.site-wrapper {
    position: relative;
    min-height: 100%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

    main {
        /** Part of footer hack **/
        padding-bottom: 360.5px; /* Height of the footer element */
    }

    main > *:first-child {
        /** 10rem , 4 for header and 6 by default **/
        padding-top: 10rem;
    }

    main > section {
        padding: 6rem 0;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    main > *:first-child {
        padding-top: 6rem;
    }

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

.account-container {
    padding-top: 6rem;
}

.danger-zone {
    border: 1px solid #ef5350;
    padding: 8px;
}

.danger-zone .danger-zone-heading {
    color: #ef5350;
    margin-top: 0;
}

.session-list {
    padding-left: 0;
}

.session-list .session-list-item {
    list-style-type: none;
    padding: 4px 8px;
    margin-bottom: 8px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    position: relative;
}

.session-list .session-list-item.current-session {
    border: 1px solid #025975;
    border-radius: 2px;
    color: #025975;
}

.session-list .session-list-item.current-session::before {
    color: #025975;
    content: "Current session";
    position: absolute;
    font-size: 12px;
    top: -18px;
    left: 8px;
}

.session-list .session-list-item .session-grid-item {
    display: flex;
    align-items: center;
}

.session-list .session-list-item .session-grid-item:last-child {
    justify-content: flex-end;
}
