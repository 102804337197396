/*
 * SOURCE SANS PRO
 */
@font-face
{
	font-family: 'Source Sans Pro';
	font-weight:normal;
	src: url('../../app/assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
}

@font-face
{
	font-family: 'Source Sans Pro';
	font-weight:300;
	src: url('../../app/assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf');
}

@font-face
{
	font-family: 'Source Sans Pro';
	font-weight:500;
	src: url('../../app/assets/fonts/Source_Sans_Pro/SourceSansPro-Semibold.ttf');
}

@font-face
{
	font-family: 'Source Sans Pro';
	font-weight:600;
	src: url('../../app/assets/fonts/Source_Sans_Pro/SourceSansPro-Semibold.ttf');
}

@font-face
{
	font-family: 'Source Sans Pro';
	font-weight:bold;
	src: url('../../app/assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf');
}

/*
 * SOURCE CODE PRO
 */

@font-face
{
	font-family: 'Source Code Pro';
	font-weight:normal;
	src: url('../../app/assets/fonts/Source_Code_Pro/SourceCodePro-Regular.ttf');
}


@font-face
{
	font-family: 'Source Code Pro';
	font-weight:500;
	src: url('../../app/assets/fonts/Source_Code_Pro/SourceCodePro-Medium.ttf');
}

@font-face
{
	font-family: 'Source Code Pro';
	font-weight:300;
	src: url('../../app/assets/fonts/Source_Code_Pro/SourceCodePro-Light.ttf');
}

@font-face
{
	font-family: 'Source Code Pro';
	font-weight:600;
	src: url('../../app/assets/fonts/Source_Code_Pro/SourceCodePro-Semibold.ttf');
}

@font-face
{
	font-family: 'Source Code Pro';
	font-weight:bold;
	src: url('../../app/assets/fonts/Source_Code_Pro/SourceCodePro-Bold.ttf');
}

