.wrapper {
	max-width: 62.5rem;
	position:relative;
	margin:0 auto;
}

.wrapper:before,
.wrapper:after {
	content: ' ';
	display: table;
}
.wrapper:after {
	clear: both
}

.row {
	*zoom: 1;
	position:relative;
}
.row:before,
.row:after {
	content: ' ';
	display: table;
}
.row:after {
	clear: both
}

.row .row {
	width:auto;
	max-width: none;
    clear:both;
}

.row .row.collapsed {
	margin:0;
}

.column {
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	width: 100%;
	min-height: 1px;
	position: relative;
	float: left;
}

.column:before,
.column:after {
	content: ' ';
	display: table;
}
.column:after {
	clear: both
}

@media screen and (max-width:769px){

	.extend-1,
	.extend-2,
	.extend-3,
	.extend-4,
	.extend-5,
	.extend-6,
	.extend-7,
	.extend-8,
	.extend-9,
	.extend-10,
	.extend-11,
	.extend-12 {
		width:100%;
	}

}

@media screen and (min-width:769px) {
	.extend-1 {
		width: 8.33333%;
	}
	.offset-1 {
		margin-left: 8.33333%;
	}
	.extend-2 {
		width: 16.66667%;
	}
	.offset-2 {
		margin-left: 16.66667%;
	}
	.extend-3 {
		width: 25%;
	}
	.offset-3 {
		margin-left: 25%;
	}
	.extend-4 {
		width: 33.33333%;
	}
	.offset-4 {
		margin-left: 33.33333%;
	}
	.extend-5 {
		width: 41.66667%;
	}
	.offset-5 {
		margin-left: 41.66667%;
	}
	.extend-6 {
		width: 50%;
	}
	.offset-6 {
		margin-left: 50%;
	}
	.extend-7 {
		width: 58.33333%;
	}
	.offset-7 {
		margin-left: 58.33333%;
	}
	.extend-8 {
		width: 66.66667%;
	}
	.offset-8 {
		margin-left: 66.66667%;
	}
	.extend-9 {
		width: 75%;
	}
	.offset-9 {
		margin-left: 75%;
	}
	.extend-10 {
		width: 83.33333%;
	}
	.offset-10 {
		margin-left: 83.33333%;
	}
	.extend-11 {
		width: 91.66667%;
	}
	.offset-11 {
		margin-left: 91.66667%;
	}
	.extend-12 {
		width: 100%;
	}

	.push-1 {
		left: 8.33333%;
	}
	.pull-11 {
		right: 91.66667%;
	}


	.push-2 {
		left: 16.66667%;
	}
	.pull-10 {
		right: 83.33333%;
	}


	.push-3 {
		left: 25%;
	}
	.pull-9 {
		right: 75%;
	}


	.push-4 {
		left: 33.33333%;
	}
	.pull-8 {
		right: 66.66667%;
	}


	.push-5 {
		left: 41.66667%;
	}
	.pull-7 {
		right: 58.33333%;
	}


	.push-6 {
		left: 50%;
	}
	.pull-6 {
		right: 50%;
	}


	.push-7 {
		left: 58.33333%;
	}
	.pull-5 {
		right: 41.66667%;
	}


	.push-8 {
		left: 66.66667%;
	}
	.pull-4 {
		right: 33.33333%;
	}


	.push-9 {
		left: 75%;
	}
	.pull-3 {
		right: 25%;
	}


	.push-10 {
		left: 83.33333%;
	}
	.pull-2 {
		right: 16.66667%;
	}


	.push-11 {
		left: 91.66667%;
	}
	.pull-1 {
		right: 8.33333%;
	}

	.column + .column:last-child {
	    float: right;
	}

	.column + .column.closure {
	    float: left;
	}

	.centered {
		margin-left: auto !important;
		margin-right: auto !important;
		float: none !important;
		/*clear: both;*/
	}

}
