/*
 * Button base rules
 * We use the BEM naming convention (See https://en.bem.info/method/naming-convention/)
 * Base font size is 16px so 1rem == 16px
 */
.goodbutton, /* Used for backwards compatibility */
.badbutton, /* Used for backwards compatibility */
.slbutton, /* Used for backwards compatibility */
.gitbutton, /* Used for backwards compatibility */
.button, /* Used for backwards compatibility */
.btn,
button {
    display: inline-block;
    position: relative;
    cursor: pointer !important;
    overflow: hidden;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 2px;
    /*
     * Since we want to achieve 32px height, with 16px as base font-size, and excluding 2px from
     * the top and bottom borders, that gives us (30 - 2)/16 = 1.875,
     * which translates to 30px height, which when adding the 2px from top and bottom borders back
     * gives a final 32px height */
    line-height: 1.875rem; /* 30px */
    min-width: 5.5rem; /* 88px */
    color: #78909C;
    font-size: .875rem; /* 14px */
    font-weight: 400;
    font-family: 'Source Sans Pro', 'Helvetica','Arial', sans-serif;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0;
    margin: 0;
    padding: 0 1rem; /* 0 16px */
    background-position: 0 0;
    background-color: transparent;
    background-image: none;
    white-space: nowrap;
    box-shadow: none;
    text-shadow: none;
}

.goodbutton:focus,
.badbutton:focus,
.slbutton:focus,
.gitbutton:focus,
.button:focus,
.btn:focus,
button:focus {
    color: #78909C;
}

.btn:active,
button:active {
    outline: none;
}

.btn.btn--2x,
button.btn--2x {
    font-size: 1.25rem; /* 20px */
    min-width: 9.375rem; /* 150px */
    line-height: 2.875rem; /* 46px for a 48px total with borders */
    padding: 0 2rem; /* 0 32px */
    font-weight: 500;
}

/*
 * Ghost button (White border with white letters)
 * Add hover and active states here so we can combine with other button styles
 */
.btn.btn--ghost:hover,
button.btn--ghost:hover {
    background: #fff;
    color: #263238;
}

.btn.btn--ghost:focus,
button.btn--ghost:focus {
    color: #fff;
}

.btn.btn--ghost:active,
button.btn--ghost:active {
    background: #D0D0D0;
    border-color: #D0D0D0;
    color: #263238;
}

/*
 * Green button
 */
.goodbutton, /* Used for backwards compatibility */
.btn.btn--cta,
button.btn--cta {
    color: #fff;
    background-color: #44B78B;
    border-color: #40AD84;
}

.goodbutton:focus,
.btn.btn--cta:focus,
button.btn--cta:focus {
    color: #fff;
}

.goodbutton:hover,
.btn.btn--cta:hover,
button.btn--cta:hover {
    color: #fff;
    background-color: #44B78B;
    border-color: #36916E;
}

.goodbutton:active,
.btn.btn--cta:active,
button.btn--cta:active {
    color: #fff;
    background-color: #3A9E78;
}

/*
 * Blue button
 */
.slbutton, /* Used for backwards compatibility */
.btn.btn--primary,
button.btn--primary {
    color: #fff;
    background-color: #026282;
    border-color: #025975;
}

.slbutton:focus,
.btn.btn--primary:focus,
button.btn--primary:focus {
    color: #fff;
}

.slbutton:hover,
.btn.btn--primary:hover,
button.btn--primary:hover {
    color: #fff;
    background-color: #026282;
    border-color: #023A4C;
}

.slbutton:active,
.btn.btn--primary:active,
button.btn--primary:active {
    color: #fff;
    background-color: #024359;
}

/*
 * Red button
 */
.badbutton, /* Used for backwards compatibility */
.btn.btn--danger,
button.btn--danger {
    color: #fff;
    background-color: #EF5350;
    border-color: #E34D4B;
}

.badbutton:focus,
.btn.btn--danger:focus,
button.btn--danger:focus {
    color: #fff;
}

.badbutton:hover,
.btn.btn--danger:hover,
button.btn--danger:hover {
    color: #fff;
    background-color: #EF5350;
    border-color: #BF413F;
}

.badbutton:active,
.btn.btn--danger:active,
button.btn--danger:active {
    color: #fff;
    background-color: #CC4643;
}

/*
 * White button
 */
.btn.btn--neutral,
button.btn--neutral {
    color: #555;
    background-color: #fff;
    border-color: #E5E5E5;
}

.btn.btn--neutral:focus,
button.btn--neutral:focus {
    color: #555;
}

.btn.btn--neutral:hover,
button.btn--neutral:hover {
    color: #555;
    background-color: #fff;
    border-color: #D1D1D1;
}

.btn.btn--neutral:active,
button.btn--neutral:active {
    color: #555;
    background-color: #DDDDDD;
}

/*
 * Ghost button (White border with white letters)
 * Add ghost button rules in the end so we can combine with other button stylings
 */
.btn.btn--ghost,
button.btn--ghost {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
}

.btn.btn--ghost.btn--link:hover {
    background-color: transparent;
    color: #fff;
}

.btn.btn--ghost.btn--primary {
    color: #026282;
    background-color: transparent;
    border-color: #023A4C;
}

.btn.btn--ghost.btn--danger {
    color: #ef5350;
    background-color: transparent;
    border-color: #bf413f;
}

/*
 * Disabled button
 */
.btn:disabled,
button:disabled,
.btn[disabled],
button[disabled],
.btn[disabled=disabled],
button[disabled=disabled],
.btn.btn--disabled,
button.btn--disabled {
    color: #b2b2b2;
    background-color: #DDDDDD;
    border-color: transparent;
    cursor: default !important;
}

.btn:disabled:hover,
button:disabled:hover,
.btn[disabled]:hover,
button[disabled]:hover,
.btn[disabled=disabled]:hover,
button[disabled=disabled]:hover,
.btn.btn--disabled:hover,
button.btn--disabled:hover {
    color: #b2b2b2;
    background-color: #DDDDDD;
    border-color: transparent;
    cursor: default !important;
}

.btn-container {
    margin-top: 8px;
}
