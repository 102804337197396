[class*="Form"] {
    font-weight: 300;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border: 1px solid rgb(175, 175, 175);
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
    position:relative;
}

[class*="Form"] label{
    font-size: 1.5em;
    font-weight: 300;
    display: inline-block;
    position: relative;
}

[class*="Form"] label:hover,
[class*="Form"] .link:hover {
    color: rgb(2,98,130);
}

[class*="Form"] .uName-div:hover label,
[class*="Form"] .uPass-div:hover label,
[class*="Form"] .uMail-div:hover label {
    color: rgb(2,98,130);
}

[class*="Form"] .buttons-div {
    margin-top: 2em;
    position: relative;
}


[class*="Form"] input[type=checkbox] {
    top: 0px;
    float: left;
    margin-right: 10px;
}

[class*="Form"] input[type=checkbox]:focus {
    border: 1px solid rgb(2,98,130) !important;
    outline: none !important;
}

[class*="Form"] div:hover input {
    border: 1px solid rgb(2,98,130);
}

[class*="Form"] div {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    position: relative;
}

.buttons-div button,
.buttons-div a.goodbutton,
.buttons-div a.slbutton {
    width: 100%;
    margin: 0;
}

#forgot-email-form .buttons-div button {
    width: auto;
}

.signupForm .buttons-div div {
    text-align: right;
}

.loginForm .buttons-div div {
    text-align: center;
}

.buttons-div div a {
    text-decoration: underline;
}

.uRembox-div label, .uForgotbox-div {
    font-size: 0.75em;
}

.uRembox-div {
    line-height: 0.75em;
    float: left;
}

#forgotPassword:hover {
    text-decoration: underline;
}

#forgotPassword {
    text-align: right;
    float: right;
}

#sign-pass-strength{
    height: 0px;
    display: block;
    transition: height .1s ease-out;
    position: relative;
}

#sign-pass-strength[data-strength] {
    height: 5px;
}

#sign-pass-strength[data-strength]::before {
    content: '';
    position: absolute;
    font-size: 0.8rem;
    right: 0;
    top: 5px;
}

#sign-pass-strength::after{
    content: '';
    width: 0;
    height: 100%;
    display: block;
    transition: width .1s ease-out, background-color .1s ease-out;
}

#sign-pass-strength[data-strength="0"] {
    border: 1px solid red;
}

#sign-pass-strength[data-strength="0"]::before {
    content: 'Very weak';
}

#sign-pass-strength[data-strength="0"]::after {
    width: 20%;
    background-color: red;
}

#sign-pass-strength[data-strength="1"] {
    border: 1px solid #FF8F00;
}

#sign-pass-strength[data-strength="1"]::before {
    content: 'Weak';
}

#sign-pass-strength[data-strength="1"]::after {
    width: 40%;
    background-color: #FF8F00;
}

#sign-pass-strength[data-strength="2"] {
    border: 1px solid #FDD835;
}

#sign-pass-strength[data-strength="2"]::before {
    content: 'Ok';
}

#sign-pass-strength[data-strength="2"]::after {
    width: 60%;
    background-color: #FDD835;
}

#sign-pass-strength[data-strength="3"] {
    border: 1px solid #44B78B;
}

#sign-pass-strength[data-strength="3"]::before {
    content: 'Strong';
}

#sign-pass-strength[data-strength="3"]::after {
    width: 80%;
    background-color: #44B78B;
}

#sign-pass-strength[data-strength="4"] {
    border: 1px solid #026282;
}

#sign-pass-strength[data-strength="4"]::before {
    content: 'Very strong';
}

#sign-pass-strength[data-strength="4"]::after {
    width: 100%;
    background-color: #026282;
}

#sign-pass-suggestions {
    font-size: 0.8rem;
    margin: 0;
    transition: margin .1s ease-out;
}

#sign-pass-suggestions.has-content {
    margin: 2rem 0 1rem;
}

#sign-pass-suggestions ul {
    padding-left: 1rem;
}
