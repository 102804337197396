.card {
    text-align: center;
    border-radius: 2px;
    color: #555555;
    overflow: hidden;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14),
        0 4px 5px 0 rgba(0, 0, 0, 0.05),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.card-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e5e5e5;
    padding: .5rem 0;
    font-size: 1.4em;
}

.card-header.smaller {
    padding: .9em 0;
}

.card-content {
    background: #fff;
    font-size : 1.2rem
}

.card .row {
    padding: 1.7rem 0;
}

.plan .card-header {
    background: #0277bd;
    border-bottom: 1px solid #1b5380;
    color: white;
}

.plan.pro .card-header {
    background-color: #014358;;
    border-bottom: 1px solid #001d26;
}

.card.simple {
    box-shadow: none;
    cursor: pointer;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.card.simple:hover {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.4);
}

.card.simple.selected {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.4);
}

.card.simple .card-header {
    padding: 0.5rem;
    transition: background-color .1s ease-out, border-color .1s ease-out;
}

.card.simple.pro .card-header {
    background-color: #828282;
    border-color: #828282;
}

.card.simple.basic .card-header {
    background-color: #bdbdbd;
    border-color: #bdbdbd;
}

.card.simple.pro.selected .card-header {
    background-color: #014358;
    border-color: #014358;
}

.card.simple.basic.selected .card-header {
    background-color: #0277bd;
    border-color: #0277bd;
}

.card.simple .card-content {
    font-size: 1.3rem;
}

.card.simple .card-content .row {
    padding: 2.032rem;
}

@media screen and (max-width:768px){
    .card {
        margin: 1em 0;
    }
}
