/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
/* Base font-size is 16px, 1rem == 16px */

header {
    background: #014358;
    width: 100%;
    z-index: 20;
    font-size: .875rem; /* 14px */
    font-weight: 400;
    top: 0;
    border-bottom: none;
    box-shadow: none;
    height: 64px;
}

.fixed {
    position: relative;
}

header .logo-container,
header .text-container {
    height : 4rem;
}

.logo-link {
    max-width: 300px;
    margin: 0 auto;
}

#navigation {
    background-color: white;
    z-index: 0;
    clear: both;
    border-bottom: 1px solid #e5e5e5;
}

#navigation li {
    margin: 0 0 0 1.2rem;
    display: inline-block;
}

#navigation li:last-child {
    margin-right: 0;
}

#navigation li:first-child {
    margin-left: 0;
}

#navigation li:not(.not-visual-link) a {
    color: #014358;
    position: relative;
    line-height: 2rem;
}

#navigation li:not(.not-visual-link) a:hover {
    color: rgb(43, 166, 203);
}

#topLogin .signup.button {
    position:relative;
}

#topLogin .signup {
    display: none;
}

#topLogin.scrolled .signup,
.page-blog #topLogin .signup,
.page-company #topLogin .signup,
.page-login #topLogin .signup,
.page-tos #topLogin .signup,
.page-privacy #topLogin .signup,
.page-features-django #topLogin .signup,
.page-features #topLogin .signup {
    display: inline-block;
}

#topLogin.scrolled .login,
.page-blog #topLogin .login,
.page-company #topLogin .login,
.page-login #topLogin .login,
.page-tos #topLogin .login,
.page-privacy #topLogin .login,
.page-features-django #topLogin .login,
.page-features #topLogin .login {
    display: none;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .fixed {
        position: fixed;
    }

    .logo-link {
        max-width: none;
        /*         margin: initial; */
    }

    #navigation {
        background-color: transparent;
        clear: none;
        border-bottom: none;
    }

    #navigation li {
        margin: 0 1.2rem 0 1.2rem;
    }

    #navigation li:not(.not-visual-link) a {
        color: #FFF;
        opacity: .8;
    }

    #navigation li:not(.not-visual-link) a:hover {
        color: #FFF;
    }

    #navigation li:not(.not-visual-link) a:hover,
    #navigation li.active a {
        opacity: 1;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
