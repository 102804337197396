.severity--warning {
    color: #f57c00;
    background: #fff3e0;
    border-color:#f57c00;
}

.severity--info {
    color: #0277bd;
    background: #e1f5fe;
    border-color:#0277bd;
}

.severity--danger,
.severity--error /* Polyfill for Django flash messages */ {
    color: #d32f2f;
    background: #ffebee;
    border-color:#d32f2f;
}

.severity--success {
    color: #00c853;
    background: #e0f2f1;
    border-color:#00c853;
}

.bordered {
    border-width:2px;
    border-style: solid;
}
