@import url('../modals.css');

html {
    background-color: #f5f5f5;
}

body.showModals {
    overflow: hidden;
}

.no-footer {
    padding-bottom: 0 !important;
}

.subheading {
    font-size: 1.3em;
    margin-top: .8rem;
}

.separated {
    border-bottom: 1px solid #e5e5e5;
}

.frozen,
.select-one .table-contents > .empty {
    color: #999999 !important;
}

.projects-table {
    background: white;
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    padding: 0;
    margin: 2rem 0;
}

.projects-table .empty {
    color: #999999;
}

.projects-table .row {
    margin: 0;
}

.projects-table .column {
    padding: 0 1.875rem;
    position: relative;
}

.projects-table .addon-name {
    color: inherit;
    transition: color .1s ease-in;
}

.projects-table .add-remove-toggler {
    float: right;
    font-weight: 400;
    cursor: pointer;
    height: 17px;
    width: 17px;
    line-height: 17px;
    text-align: center;
    position: relative;
    top: 3px;
    border-radius: 100px;
    box-shadow: 0 0 0 1px white;
    visibility: hidden;
    transform: scale3d(0.8, 0.8, 1);
    transition: all .1s ease-in; /*HACK: transition all to apply to visibility as well*/
}

.projects-table .add-remove-toggler.remove {
    visibility: visible;
    box-shadow: 0 0 0 1px #999;
    color: #999;
}

.projects-table .add-remove-toggler.add {
    visibility: visible;
    box-shadow: 0 0 0 1px #4CAF50;
    color: #4CAF50;
    transform: rotateZ(45deg);
    font-weight: bold;
}

.projects-table .frozen .add-remove-toggler {
    visibility: hidden;
}

.projects-table .table-header {
    font-size: 1.1em;
    font-weight: 500;
    padding: 0.6em 0;
}

.projects-table .table-contents {
    position: relative;
}

.projects-table .table-contents .indicator-element {
    height: 0;
    box-shadow: 0 0 0 0 transparent;
    position: absolute;
    width: 100%;
    max-height: 100%;
    transition: height .2s ease-out, border-radius .2s ease-out, box-shadow .2s ease-out;
}

.projects-table.select-one .table-contents .indicator-element {
    box-shadow: 0 0 0 2px #0277bd;
}

.projects-table.select-all .table-contents .indicator-element {
    box-shadow: 0 0 0 2px #014358;
}

.projects-table .table-contents .project-descriptor {
    padding: .5rem 0;
    transition: color .2s ease-out, background-color .2s ease-out;
}

.projects-table .table-contents .project-descriptor.frozen,
.select-one .table-contents > .empty {
    background-color: #f5f5f5;
}

.projects-table .table-contents .project-descriptor:last-child {
    border: none;
}

.warning.frozen {
    margin-top: -0.5em;
    margin-bottom: 0;
}

.warning.frozen.hide {
    visibility: hidden;
}

.warning.hide + .subheading {
    margin-top: 1.8rem;
}

.plans-blurb {
    margin-bottom: 1rem;
}

.row.cta-aftertrial {
    margin-top: 4rem;
}

#total-cost {
    font-size: 2rem;
}

.row.cta-aftertrial button {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

/*
* MODAL CSS
*/
.modals-container {
    display: none;
    position: fixed;
    background: rgba(0,0,0,.8);
    z-index: 10;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal {
    display: none;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate3d(-50%, 0, 0);
    width: 500px;
    background: white;
    padding: 1rem 1.5rem;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0  6px 30px 5px rgba(0, 0, 0, 0.12),
        0  8px 10px -5px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    bottom: initial; /** Overrides bootstrap's rule **/
}

.modal__header {
    padding: 0.6rem 0 0;
}

.modal__title {
    margin: 0 !important;
    float: left;
    font-weight: 500;
}

button.modal__close {
    float: right;
    font-size: 2rem;
    line-height: 2rem;
    cursor: pointer;
    opacity: .5;
    transition: opacity .1s ease-in;
    margin: 0;
    padding: 0;
    min-width: 0;
    background: none;
    border: none;
    text-shadow: none;
    color: rgb(24, 24, 24) !important;
    box-shadow: none;
}

.modal__close:hover,
.modal__close:focus{
    opacity: 1;
}

.modal__body {
    padding: 1rem 0;
    position: relative;
    width: 200%;
    transition: all .2s ease-in;
}

.modal--delete-account .modal__body {
    transform: translate3d(-52.6%, 0, 0);
}

.modal__info {
    opacity: 1;
    width: 50%;
    float: left;
    margin-right: 1.5rem;
    transition: opacity .1s ease-in;
    transform: translateY(50%);
}


.modal__img {
    float: left;
    height: 80px;
    width: 80px;
    border-radius: 100px;
    margin-right: 1rem;
}

.modal__user-name {
    margin-top: 0.4rem;
    font-size: 1.5rem;
}

.modal__confirmation {
    opacity: 0;
    width: calc(50% - 1.5rem);
    display: inline-block;
    transition: opacity .1s ease-in;
}

.modal--delete-account .modal__confirmation {
    opacity: 1;
}

.modal__label {
    margin-top: 0.4rem;
    margin-bottom: 0;
    font-size: 1.4rem;
    font-weight: 300;
}

.modal__input {
    margin-bottom: 0;
    margin-top: 0.7rem !important;
}

.modal__footer {
    text-align: right;
}

button.modal__action,
button.modal__action {
    margin: 0 0 0 1rem;
    transition: all .2s ease-in;
}

button.modal__action--primary {
    color: #E53935 !important;
}

button.modal__action--primary:disabled {
    color: #999 !important;
}

button.modal__action--secondary {
    opacity: 0;
    visibility: hidden;
    color: #78909C !important;
}

.modal--delete-account .modal__action--secondary {
    visibility: visible;
    opacity: 1;
}
