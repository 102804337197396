.welcome-message-container p {
    text-align: center;
    letter-spacing: 0.01em;
}

.welcome-message-container .main-paragraph {
    font-size: 1.4em;
}

.welcome-message-container .main-paragraph strong {
    font-weight: 600;
}

.welcome-message-container .button-container {
    margin: 3em 0;
    text-align: center;
}

.welcome-message-container .button-container a {
    margin: 0;
    font-weight: 400;
    font-size: 1.1em;
}

.welcome-message-container .footnote {
    font-size: .9em;
}
