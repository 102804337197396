/* ==========================================================================
 Helper classes
 ========================================================================== */

/*
 * Image replacement
 */

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    /* IE 6/7 fallback */
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

.hidden {
    display: none !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp$.com/p
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screenreaders, but maintain layout
 */

.invisible {
    visibility: hidden !important;
    opacity:0 !important;
}

/*
 * Only set opacity to 0
 */

.transparent {
    opacity:0 !important;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

/*Use this class on elements that can be anchored to offset the fixed header*/
.anchor-offset {
    border-top: 4em solid transparent;
    margin-top: -4em !important;
}

/*
 * Darken areas when out of focus
 */

.oof {
    -webkit-filter: brightness(0.4) !important;
    -moz-filter: brightness(0.4) !important;
    filter: brightness(0.4) !important;
    pointer-events: none;
}

/*
 * Similar as above but grays out
 */
.gray-out {
    -webkit-filter: grayscale(80%);
    -moz-filter: grayscale(80%);
    filter: grayscale(80%);
}

/*
 * Include this class to an element whose children you want to vertically align
 */
.center-vertical::before,
.center-vertical-children::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: 0;
}

.center-vertical > *,
.center-vertical-children > * {
    display: inline-block;
    vertical-align: middle;
}

/*
 * Contrary to center-vertical, include this class to an element you want to vertically align,
 * given the parent element has a specified height.
 */
.center-vertical-self {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
}

/*
 * Crop overflow
 */
.no-overflow {
    overflow: hidden;
}

/*
 * Make unselectable text
 */
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

/*
 * Make things unreactive
 * https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events
 */
.unreactive {
    pointer-events: none;
}

/*
 * Helper for positioning after and before pseudoelements
 */
.buttonWrapper {
    position:relative;
}

.fauxbold {
    font-weight:400;
}

.underlined {
    text-decoration: underline !important;
}

.link {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

.allclear {
    clear: both;
}

.fixed {
    position: fixed;
}

.uppercase {
    text-transform: uppercase;
}

.loading {
    opacity: .7 !important;
}

.fallback {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,.7);
    z-index:10000;
}

.raised,
.raised-low {
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.3) !important;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.14),
        0 4px 5px 0 rgba(0, 0, 0, 0.05),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.2) !important
}

.raised-medium {
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.35) !important;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.4) !important
}

/*
 * Borrowing some material CSS for prettier, fuller shadows in elements such as pin buttons, modals, etc
 * https://elements.polymer-project.org/elements/paper-material?active=paper-material&view=demo:demo/index.html
 */
.paper-material {
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.paper-material[data-elevation="1"] {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.paper-material[data-elevation="2"] {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.4);
}

.paper-material[data-elevation="3"] {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.4);
}

.paper-material[data-elevation="4"] {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 5px 5px -3px rgba(0, 0, 0, 0.4);
}

.paper-material[data-elevation="5"] {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0  6px 30px 5px rgba(0, 0, 0, 0.12),
        0  8px 10px -5px rgba(0, 0, 0, 0.4);
}

/*FONT WEIGHT ADJUSTMENTS*/
strong.fauxbold,
b.fauxbold,
.semibold {
    font-weight: 500;
}

.hyper-z {
    position: relative;
    z-index : 999999999;
}

/*THESE TWO CLASSES SHOULD NEVER HAVE ANY PRESENTATIONAL ELEMENT ATTACHED TO THEM*/
.active, .selected, .fauxhover {}

.flash-message {
    margin: 1em 0 0px;
    padding: 15px 20px;
    border-radius: 5px;
}
