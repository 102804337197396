/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

.editorconfig-logo-container {
    margin: 32px 0;
}

.editorconfig-logo {
    width: auto;
    display: block;
    margin: 0 auto;
}


.detailed-feature-page-feature-set h2,
.feature-set h2 {
    font-weight: 300;
    font-size: 2em;
    margin-bottom: 0.4em;
    text-align: left;
}

.detailed-feature-page-feature-set p code {
    background-color: #ffc;
    font-size: 0.8em;
    padding: 0.2em;
    color: rgb(85, 85, 85);
}

.detailed-feature-page-feature-set h4 code {
    color: rgb(85, 85, 85);
    font-size: 100%;
    background-color: transparent;
    font-weight: 600;
}

.detailed-feature-page-feature, .feature {
    margin-bottom: 96px;
}

.detailed-feature-page-feature-set p, .feature-set p {
    font-size: 1.2em;
    line-height: 1.6em;
    text-align: left;
}

.html.feature-content, .php.feature-content {
    padding-top: 6em;
    box-shadow: none !important;
}

.django.feature-content {
    box-shadow: none !important;
}

.detailed-feature-page-feature:nth-child(even) .detailed-feature-page-feature-thumbnail, .feature:nth-child(even) .feature-thumbnail {
    float: right;
}

.more-features h2, .ready-to-start h2 {
    text-align: center;
    font-weight: 200;
    font-size: 2.5em;
}

.ready-to-start h2 {
    margin-bottom: 0 !important;
    padding-bottom: 2rem;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    #other-features h3 {
        height: 2rem;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    #other-features h3 {
        height: auto;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
