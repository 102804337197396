.loginForm+div {
	margin: 1em 0 0 0;
}

.loginForm.row {
	-webkit-transition: -webkit-transform .5s ease, opacity .5s ease;
	-moz-transition: -moz-transform .5s ease, opacity .5s ease;
	-ms-transition: -ms-transform .5s ease, opacity .5s ease;
	-o-transition: -o-transform .5s ease, opacity .5s ease;
	transition: transform .5s ease, opacity .5s ease;
}

main.page-login .offset {
	-webkit-transform: translate(0, -10%);
	-moz-transform: translate(0, -10%);
	-ms-transform: translate(0, -10%);
	-o-transform: translate(0, -10%);
	transform: translate(0, -10%);
}
