#content[data-page="students"] > #banner {
  background-color: #f5f5f5;
}

#content[data-page="students"] h1,
#content[data-page="students"] p {
  color: #555555;
}

#content[data-page="students"] #additional-features {
  background: #f5f5f5;
}

#content[data-page="students"] #additional-features img {
  border: 1px solid #ccc;
  border-radius: 1000px;  /** Too big; make it circle */
  margin: 0 auto;
  width: 50%;
}
