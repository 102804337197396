#companyPageContent {
    max-width: 60rem;
}

main.page-company h1::after {
    content:"";
    position:absolute;
    height:1px;
    width:100%;
    bottom:0;
    background: -moz-linear-gradient(left,  rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.35) 50%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0.35)), color-stop(50%,rgba(0,0,0,0.35)), color-stop(100%,rgba(0,0,0,0)));
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(left,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(left,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to right,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#59000000', endColorstr='#00000000',GradientType=1 );
}

main.page-company #pageNavigation {
    padding-top: 1em;
}

main.page-company #pageNavigation .row {
    padding: 5px 10px;
    font-size: 1.2em;
    font-weight: 300;
}

main.page-company .sectionLetter {
    float: left;
}

main.page-company .sectionName {
    overflow: hidden;
    display: block;
    padding-left: 5px;
}

main.page-company #sectionsWrapper p,
main.page-company #sectionsWrapper li {
    text-align:justify;
}

main.page-company #sectionsWrapper li {
    padding: 5px 0;
}

main.page-company section h2 {
    font-size: 1.5em;
    font-weight: 400;
    position: relative;
    padding: 1em 0 .5em 0;
    padding-right: 60px;
    margin: 0;
    border:1px solid transparent;
    -webkit-transition: padding .3s ease, border .3s ease;
    -moz-transition: padding .3s ease, border .3s ease;
    -ms-transition: padding .3s ease, border .3s ease;
    -o-transition: padding .3s ease, border .3s ease;
    transition: padding .3s ease, border .3s ease;
}

main.page-company section h2::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.35);
}

main.page-company section h2::before {
    display: block;
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-right: 1px solid rgba(0, 0, 0, .35);
    border-bottom: 1px solid rgba(0, 0, 0, .35);
    background-color: whitesmoke;
    left: 25px;
    bottom: -7px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
    -webkit-transition: left .5s ease;
    -moz-transition: left .5s ease;
    -ms-transition: left .5s ease;
    -o-transition: left .5s ease;
    transition: left .5s ease;
}

#prologue,
#vision,
#mission {
    text-align:justify;
}

#prologue {
    margin: 4em 0;
    position:relative;
}

#prologue p {
    text-align:center;
    font-size: 1.5em;
    margin: 0 auto;
    font-weight: 300;
    float: none;
    position:relative;
}

#prologue p:last-child {
    margin-top: 16px;
}

#imageShowcase {
    position: relative
}

#compScreen, #tabScreen, #phoneScreen {
    position:absolute;
    color:whitesmoke;
    box-sizing: border-box;
    font-family: "Source Code Pro";
}

.yourCode, .slCloud {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    -webkit-transition: opacity .5s ease;
    -moz-transition: opacity .5s ease;
    -ms-transition: opacity .5s ease;
    -o-transition: opacity .5s ease;
    transition: opacity .5s ease;
    opacity:0;
}

.noTransition {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

#compScreen {
    position: absolute;
    width: 37.5%;
    height: 48.7%;
    top: 19.7%;
    left: 5.5%;

}

#compScreen .yourCode {
    padding:1em;
}

#tabScreen {
    position: absolute;
    width: 19.3%;
    height: 25.2%;
    top: 31.3%;
    left: 56.3%;
}

#tabScreen .yourCode {
    padding: 1em;
    font-size: .9em;
}

#phoneScreen {
    position: absolute;
    width: 5.7%;
    height: 17.2%;
    top: 35.3%;
    left: 88.5%;
}

#phoneScreen .yourCode {
    padding: .6em .3em;
    font-size: .6em;
}

#statements {
    margin-bottom: 3em;
}

#team h2 {
    padding-left:0;
    padding-right:0;
    margin: 0 !important;
}

#team .column {
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    -o-perspective: 800px;
    perspective: 800px;
}

#team .row:nth-of-type(1) .column {
    z-index: 2;
}

#team .row:nth-of-type(2) .column {
    z-index: 1;
}

main.page-company .member {
    margin-top: 2em;
    border: 1px solid rgba(0, 0, 0, 0.25);
    position:relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

main.page-company .member:hover {
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
    cursor:pointer;
}

main.page-company .member.flipped {
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -ms-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
}

main.page-company .member.flipped .back {
    -webkit-backface-visibility: visible;
    -moz-backface-visibility: visible;
    -ms-backface-visibility: visible;
    -o-backface-visibility: visible;
    backface-visibility: visible;
    background-color: white;
}

main.page-company .member:before,
main.page-company .member:after {
    content: "";
    position: absolute;
    -webkit-transition: opacity .3s ease, border .3s ease;
    -moz-transition: opacity .3s ease, border .3s ease;
    -ms-transition: opacity .3s ease, border .3s ease;
    -o-transition: opacity .3s ease, border .3s ease;
    transition: opacity .3s ease, border .3s ease;
    z-index: 1;
}

main.page-company .member:before {
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-right: 9px solid #dddddd;
    -webkit-transform: rotate(-95deg);
    -moz-transform: rotate(-95deg);
    -ms-transform: rotate(-95deg);
    -o-transform: rotate(-95deg);
    transform: rotate(-95deg);
    bottom: 12px;
    right: 17px;
}

main.page-company .member:after {
    border: 0 solid transparent;
    border-bottom: 3px solid #dddddd;
    border-radius: 0px 0px 0px 12px;
    bottom: 10px;
    right: 20px;
    width: 15px;
    height: 15px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

main.page-company .member:hover::before, .member:hover::after {
    opacity:1;
}

main.page-company .member:hover::before {
    border-right: 9px solid rgb(0, 127, 165);
}

main.page-company .member:hover::after {
    border-bottom: 3px solid rgb(0, 127, 165);
}

main.page-company .member .front,
main.page-company .member .back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: white;
}

main.page-company .member .back {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

main.page-company #investors .member.clearfix::after,
main.page-company #investors .member.clearfix::before {
    border: none;
}

main.page-company .imageContainer {
    overflow: hidden;
    min-height: 10em;
    height: 15em;
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,.25);
}


main.page-company .card {
    height: 8em;
    display:table-cell;
    vertical-align:middle;
    padding:0em 1em;
    text-align:center;
    width: 100em; /*force it to take max width without overflowing parent element, by default*/
}

main.page-company .memberName {
    font-size: 1.7em;
    font-weight: 300;
    line-height: 1.1em;
    margin-bottom:.5em;
}

main.page-company .memberRole {
    font-weight: 300;
}

main.page-company .back h2 {
    padding: 1em;
    text-align: center;
    font-size: 2em;
    border:none;
    font-weight: 300;
    background-color: rgb(240, 240, 236);
    box-shadow: 0px 1px 0px 0px rgba(255,255,255,1) inset;
}

main.page-company .back h2::before {
    background-color: rgb(240, 240, 236);
    border-right: 1px solid rgba(0,0,0,0.25);
    border-bottom: 1px solid rgba(0,0,0,0.25);
    left: 50%;
    margin-left: -8px;
}

main.page-company .back h2::after {
    background-color: rgba(0,0,0,0.25);
}

/*
 * Specific offsets for each photo
 */

main.page-company .back .infoContainer {
    display: table-cell;
    vertical-align: middle;
    height: 15em;
    width: 100em;
    padding: 0 1em;
    text-align: center;
    font-weight:300;
}

main.page-company .back .email,
main.page-company .back .twitter {
    padding: 1em 0;
}

main.page-company .back .email div:first-child,
main.page-company .back .twitter div:first-child {
    font-size: 1.7em;
}

#prologue, #statements, #team {
    margin:4em 0;
}

@media only screen and (max-width: 768px) {
    #imageShowcase {
        display:none;
    }

    #prologue p {
        text-align:justify;
    }
}
