@import url('../components/card.css');

main.page-pricing #for-education-section {
    margin-top: 64px;
}

main.page-pricing h1 {
	text-align: center !important;
	position: relative;
}

main.page-pricing .not-sure {
    text-align: center;
    padding: 16px 0;
}

main.page-pricing .not-sure .sentence {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.6rem;
}

main.page-pricing .plans-table,
main.page-pricing .addons-table {
	width: 100%;
}

main.page-pricing .specs {
    text-align: left;
}

main.page-pricing .specs .header {
    font-size: 1.2rem;
}

main.page-pricing .specs li::before {
    content: "\2714";
    margin-right: 8px;
    color: green;
}

main.page-pricing .upgrade-account-row {
	margin-top: 16px;
}

main.page-pricing h2 {
	font-weight: 600;
    font-size: 1.09rem;
}

main.page-pricing  .container > .row {
    margin: 2rem 0;
}

main.page-pricing  .desc p {
    font-size: 1.2rem;
}

main.page-pricing  .addon.card .v-align {
    padding-top: .5rem;
}

main.page-pricing  .addon.card .smaller-text {
    font-size: .9rem;
}

main.page-pricing  .row.cta {
    margin: 1.5rem auto 0!important;
    text-align: center
}

.cta .no-payment {
    margin-top: .5rem;
    font-size: 0.8rem;
}

.row.contact {
    margin: 2.5rem;
    font-size: .9rem;
}

@media screen and (max-width:768px){
    main.page-pricing .specs {
        text-align: center;
    }

    main.page-pricing .specs .column{
        margin-bottom: 1rem;
    }
}
