.steps-list {
    margin-bottom: 2rem;
    border-radius: 2px;
}

.step {
    border: 1px solid #D6D6D6;
    padding: 1rem;
}

.step--done {
    background: white;
}

.step-2 {
    border-top: none;
    border-left: 1px solid #D6D6D6;
}

.steps-list .step-name {
    font-weight: 600;
}

.current-step {
    padding-left: 0;
}

.step-desc h3 {
    margin-top: 0;
    font-weight: 400;
}

.current-step-2 .step-1 {
    opacity: 0.6;
}

.current-step-2 .step-1 .step-name::before {
    content: '\2714';
    color: green;
    margin-right: 8px;
    display: inline-block;
    width: 15px;
    position: absolute;
    left: -1.4rem;
}

.step-name,
.step-desc {
    margin-left: 23px;
    position: relative;
}

.current-step-1 .step-1,
.current-step-2 .step-1,
.current-step-2 .step-2 {
    background-color: white;
}

.stories-container,
.signup-container {
    font-size: 1.1em;
    position: relative;
}

.paper {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
    border: none;
    background-color: white;
    border-radius: 2px;
    padding: 1.5rem 0.9375em;
}

/*
 * User story step related
 */
.stories-container h3 {
    font-weight: 400;
    margin: 0.7em 0.6em 1.5em;
}

.stories-container label {
    padding: 0.1em 1em;
    display: inline-block;
    margin-bottom: 1.2em;
}

.stories-container input[type="radio"],
.stories-container input[type="checkbox"] {
    margin-right: 0.8em;
}

.stories-container input[type="checkbox"] {
    position: relative;
    top: 0.09em;
    margin-right: 4px;
    border-radius: 2px;
}

.stories-container .row {
    margin-left: 0.6em;
    margin-right: 0.6em;
}

.stories-container .row h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.stories-container .goodbutton {
    margin: .2em 1em 0 1em;
    font-weight: 400;
}

.stories-container textarea {
    margin: 1em;
    width: 50%;
    border-radius: 2px;
}

/*
 * Join us step related
 */
.signup-container button {
    font-weight: 400;
}

.signup-container .github-related button {
    margin-bottom: 0;
    min-width: 65%;
}

.signup-container .github-related h3 {
    display: none;
}

.signup-container .github-related .octocat{
    margin-left: 0;
    opacity: 1;
    width: 32px;
    height: 32px;
    margin: 0 auto 1.5em;
    display: block;
}

.signup-container > .paper:last-child {
    transform: translateY(75%);
}

.circular-or {
    margin: 0 auto;
    position: relative;
    background: white;
    border-radius: 100px;
    border: 1px solid #D6D6D6;
    transform: translateY(300%);
    width: 50px;
    height: 50px;
}

.circular-or span {
    position: absolute;
    top: 22%;
    left: 29%;
    font-size: 1.2em;
    font-weight: 300;
}

#story-form label {
    font-weight: 400;
}

@media (min-width: 992px) {
    .step-2 {
        border-top: 1px solid #D6D6D6;
        border-left: none;
    }
}

@media screen and (max-width:768px){
    .signup-container .paper:last-child {
        position: relative;
        transform: none;
        margin-top: -0.2em;    /* this is to make it look as part of the form*/
        top: initial;
        right: initial;
    }

    .circular-or {
        display: none;
    }

    .signup-container .github-related button {
        width: 100%;
    }
}
